<template>
  <div :class="[ 'n-select', {'n-inline': inline}, {'n-focused': focused}, ]" tabindex="-1" @click="s_click"
  >
    <n-dropdown ref="dropdown" v-bind="dropdownProps" :value.sync="s_value" fit :open="focused" :search="search" target=".n-wrapper" v-on="dropdownEvents">
      <div class="input-place">
        <div class="bank-logo"><img :src="s_value?.bankIcon" alt=""></div>
        <div class="card-content">
          <div class="title-pay-system">{{ s_value?.issuer?.toUpperCase() }}</div>
          <div class="issuer">
            <img :src="s_value?.issuerIcon" alt="">
            <div class="dot"></div>
            <input class="code" :value="s_value?.title?.substr(s_value.title.length-4, s_value.title.length-1)" type="text">
            <n-input
              ref="input"
              class="input" v-bind="inputProps" :focused="focused"
              :value="s_value?.title?.substr(s_value.title.length-4, s_value.title.length-1)"
              :update:value="updateInputValue" @input="s_input" />
          </div>
        </div>
        <div class="icon-select">
          <img :src="arrow" alt="" :class="{'active': focused}">
        </div>
      </div>
      <template #group="{item}"><slot name="group" :item="item"></slot></template>
      <template #item="{item}">
        <slot v-if="item.id === 'add'">
          <button class="add-btn-card" @click.stop="addCard">
            <span class="image"><img :src="plus" alt=""></span>
            <t name="1.10.1" />
          </button>
        </slot>
        <slot v-else name="item" :item="item">
          <div class="card">
            <div class="bank-logo"><img :src="item.bankIcon" alt=""></div>
            <div class="card-info">
              <div class="title-pay-system">{{ item.issuer.toUpperCase() }}</div>
              <div class="issuer">
                <img :src="item.issuerIcon" alt="">
                <div class="dot"></div>
                <div>{{ item.title.substr(item.title.length-4, item.title.length-1) }}</div>
              </div>
            </div>
          </div>
        </slot>
      </template>
    </n-dropdown>
    <div v-if="danger" class="n-message danger">{{ danger }}</div>
  </div>
</template>

<script>
import isArray from 'lodash/isArray'
import props from '/node_modules/nast-ui/components/Select/props.js'
import { dropdownProps, inputProps, } from '/node_modules/nast-ui/components/Select/utils.js'
import { getValue, } from 'nast-ui/utils/functions'
import clickOutside from 'nast-ui/directives/click-outside'
import arrow from 'assets/arrow-right.svg'
import plus from 'assets/plus.svg'

export default {
  name: 'NSelect',
  directives: { clickOutside, },
  mixins: [ props, ],
  data() {
    return {
      focused: false,
      search: '',
      arrow,
      plus,
    }
  },
  computed: {
    s_value: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('update:value', value)
        this['update:value'](value)
      },
    },
    dropdownProps() {
      return dropdownProps(this)
    },
    dropdownEvents() {
      return {
        select: this.s_select,
      }
    },
    inputProps() {
      return {
        ...inputProps(this),
        value: this.s_value,
        danger: false,
        autocomplete: false,
      }
    },
    multi() {
      return isArray(this.value)
    },
  },
  watch: {
    data() {
      this.s_value = this.data[1]
    },
  },
  methods: {
    addCard() {
      this.focused = false
      this.$emit('addcard')
    },
    onFocusin(e) {
      if (!this.disabled && this.$refs.dropdown) {
        this.updateFocused(true)
      }
      this.$emit('focusin', e)
      this.focusin(e)
    },
    onFocusout(e) {
      if (this.$refs.dropdown) {
        const popup = this.$refs.dropdown.$el.querySelector('.n-popup-container')
        const input = this.$refs.input.$el.querySelector('input')
        if (popup !== e.relatedTarget && input !== e.relatedTarget) {
          this.search = ''
          this.updateFocused(false)
        }
      }
      this.$emit('focusout', e)
      this.focusout(e)
    },
    s_input(value) {
      this.search = value
    },
    updateInputValue(value) {
      if (value && (this.value.length === value.length - 1)) { // created new value
        if (this.creatable) {
          this.s_value = value
        }
      } else {
        this.s_value = value
      }
    },
    updateFocused(value) {
      if (this.$refs.dropdown) {
        if (value) {
          this.$refs.input.focus()
        }
        if (this.focused) {
          this.focused = false
        } else {
          this.focused = value
        }
      }
    },
    getValue(item) {
      return getValue(item, this.itemValue)
    },
    s_select(item) {
      this.$emit('select', item)
      this.select(item)
      this.search = ''
      if (!this.multi) {
        setTimeout(() => {
          this.focused = false
        }, 2) // слишком быстрое закрытие не очень комфортно
      }
    },
    s_click() {
      if (!this.disabled) {
        this.updateFocused(true)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.add-btn-card{
  border: none;
  background: transparent;
  font-size: 16px;
  color: #b4b4b4;
  display: flex;
  align-items: center;

  .image{
    margin-right: 26px;
  }
}

.bank-logo{
  width: 44px;
  height: 44px;
  border-radius: 15px;
  background-color: #edeeee;
  margin-right: 15px;
  padding: 6px;

  img {
    width: 100%;
    height: 100%;
  }
}

.input{
  position: absolute;
  bottom: 0;
  top: 0;
  right: 0;
  left: 0;
  opacity: 0;
}

.code{
  width: 43px;
  border: none;
  font-size: 12px;
  line-height: 18px;
  color: #1c1c1c;
  margin-left: 4px;
}

.dot{
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background-color: #C5C5C5;
}

.title-pay-system{
  font-size: 16px;
  color: #1c1c1c;
  margin-bottom: 6px;
}

.icon-select{
  width: 15px;
  height: 15px;
  position: absolute;
  right: 10px;
  top: 15px;

  img{
    transform: rotate(90deg);
    width: 100%;
    height: 100%;
    transition: 0.2s;
    &.active {
      transform: rotate(-90deg);
      transition: 0.2s;
    }
  }
}

.input-place {
  display: flex;
  align-items: center;

  .card-content {
    .dot{
      margin-left: 4px;
    }

    .issuer {
      display: flex;
      align-items: center;
  }
}
  ::v-deep {
    .n-popup-wrapper .n-popup-content {
      right: 0 !important;
      left: 0 !important;
    }
  }
}
.card {
  display: flex;
  align-items: center;
  padding-left: 0 !important;
  img {
    margin-right: 4px;
  }

  .bank-logo{
    margin-left: 0 !important;
  }

  .issuer {
    display: flex;
    align-items: center;

    .dot {
      margin-right: 4px;
    }

    div{
      font-size: 12px;
      line-height: 18px;
      color: #1c1c1c;
    }
  }
}
.n-select {
  --n-popup-width: var(--n-input-width);
  --n-progress-height: 2px;
  position: relative;
  outline: none;
  cursor: text;
  text-align: left;

  &.n-inline {
    display: inline-block;
  }
  &:not(.n-inline) {
    width: 100%;
  }

  .n-message {
    margin-top: 5px;
    color: var(--danger)
  }

  &::v-deep {
    .n-input .n-icon-right {
      transition: transform var(--transition);
      opacity: .7;
      margin: 0 7px;
    }

    .n-dropdown .n-content{
      padding: 0 10px;
    }
  }
  &.n-focused::v-deep {
    .n-input .n-icon-right {
      transform: rotate(180deg);
    }
  }
}
</style>
