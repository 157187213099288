<template>
  <div class="c-modal-message">
    <div class="message">
      <div class="message-container">
        <n-loader :loading="$var('load')" />
        <div class="danger" v-html="$var('message')"></div>
        <div class="title">
          Выберите компанию
        </div>
        <div class="companies">
          <div v-for="item in companies" :key="item.id" class="company" @click="changeActiveCompany(item.id)">
            <div class="circle" :class="{'active': item.id === user.selectedCompany}" />
            <div class="title">{{ item.title }}</div>
          </div>
        </div>
        <div class="buttons">
          <n-button flat class="modal-button" @click="close"><t name="1.11.8" /></n-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'CModalMessage',
  props: {
    companies: {
      required: true,
      type: Array,
    },
  },
  data() {
    return {
      user: $app.auth.user(),
    }
  },
  methods: {
    changeActiveCompany(id) {
      this.$var('load', true)
      $api.users.edit(this.user.id, { selectedCompany: id, }).then(() => {
      }).catch((response) => {
        this.$var('message', $app.secure.clean(response.response.data.message.replace(/\n/g, '<br>')))
      }).finally(() => {
        this.close()
        this.$var('load', false)
      })
    },
    close() {
      this.$emit('update:show', false)
    },
  },
}
</script>

<style scoped lang="scss">
.c-modal-message {
  position: fixed;
  display: flex;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 1020;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, .2);
  align-items: center;
  justify-content: center;
  .companies {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .message {
    position: absolute;
    background-color: #ffffff;
    border-radius: 16px;
    width: 335px;
    .message-container {
      position: relative;
      margin: 16px;
      .title {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 18px;
        color: #000000;
        font-weight: bold;
      }
      .buttons {
        display: flex;
        justify-content: center;
        align-items: center;
        .modal-button {
          width: 145px;
          height: 45px;
          color: #ffffff;
          background-image: linear-gradient(90deg, #6e1a8d 0%, #b010da 100%);
          border-radius: 8px;
          font-size: 14px;
          &.agree {
            color: #DB0007;
            margin-right: 12px;
          }
          &.cancel {
            color: #000000;
          }
        }
      }
    }
  }
  .company {
    display: flex;
    align-items: center;
    background-color: #faf9f9;
    padding: 5px;
    border-radius: 12px;
    margin-bottom: 10px;
    &:last-child {
      margin-bottom: 0;
    }
    .title {
      font-weight: normal !important;
    }
    .circle {
      width: 18px;
      height: 18px;
      border-radius: 50%;
      border: 1px solid #c8c7c7;
      margin-right: 15px;
      &.active {
        background-color: var(--primary);
      }
    }
  }
}
.close-modal {
  display: none;
}
.danger {
  color: rgb(236, 90, 90);
}
</style>
