<template>
  <div>
    <div v-for="notify in notifies" :key="notify.id" class="n-notify" @click=" $app.ui.closeNotify(notify.id)">
      <div class="notify-body" :class="notify.color">
        <div class="image-place">
          <img :src="notify.color === 'danger' ? imgError : imgSuccess" alt="">
        </div>
        <div class="notify-text" :class="notify.color">
          {{ notify.message }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import imgSuccess from 'assets/notify/success-msg.svg'
import imgError from 'assets/notify/error-msg.svg'

export default {
  name: 'NNotify',
  data() {
    return {
      imgSuccess,
      imgError,
    }
  },
  computed: {
    notifies() {
      return $app.store.state('ui.notifies')
    },
  },
}
</script>

<style lang="scss" scoped>
.n-notify {
  width: 100%;
  z-index: 1001;
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  overflow-y: auto;
  padding: 25px;

  .notify-body {
    width: 100%;
    border-radius: 12px;
    padding: 10px;
    background-color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    &::after{
      content: '';
      position: absolute;
      left: -30px;
      right: -30px;
      top: -100%;
      bottom: 0;
      z-index: -1;
      background-color: #ffffff;
    }

    .image-place {
      width: 36px;
      height: 36px;
      margin-right: 10px;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }

  .danger {
    border: none;
  }

  .success {
    border: none;
  }

  .notify-text {
    font-size: 1.2em;
    border: none;
    line-height: 20px;
    &.danger {
      color: rgb(236, 90, 90);
    }

    &.success {
      color: #b010da;
    }
  }

}
</style>
